<template>
  <b-card-actions title="Filters" action-collapse collapsed>
    <b-card-text>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Pathway</label>

          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="pathwayFilter"
            :options="pathwaySuggestions"
            class="w-100"
            :reduce="(val) => val.value"
            @search="searchForPathway"
            @input="(val) => $emit('update:pathwayFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Creation Start Date</label>

          <b-form-datepicker
            class="w-100"
            id="cohort-data-program-start"
            :value="createAtStartFilter"
            @input="(val) => $emit('update:createAtStartFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Creation End Date</label>

          <b-form-datepicker
            class="w-100"
            id="cohort-data-program-end"
            :value="createAtEndFilter"
            @input="(val) => $emit('update:createAtEndFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card-actions>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { onUnmounted, ref } from '@vue/composition-api';
import { VueAutosuggest } from 'vue-autosuggest';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BFormDatepicker,
    vSelect,
    VueAutosuggest,
    BCardActions,
  },
  props: {
    pathwayFilter: {
      type: [String, null],
      default: null,
    },
    pathwayOptions: {
      type: Array,
      required: true,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    createAtStartFilter: {
      type: [String, null],
      default: null,
    },
    createAtEndFilter: {
      type: [String, null],
      default: null,
    },
  },
  methods: {
    pathwayFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const pathwaysPromise = this.$store.dispatch('app-placement/fetchCohorts', {
          search: this.pathwayQuery,
        });

        Promise.all([pathwaysPromise]).then((values) => {
          this.pathwaySuggestions = [];

          const pathways = values[0].data.results;

          pathways.length && this.pathwaySuggestions.push({ name: 'pathways', data: pathways });
        });
      }, 250);
    },
    pathwayRenderSuggestion(suggestion) {
      return suggestion.item.name;
    },
    pathwayGetSuggestionValue(suggestion) {
      const { name, item } = suggestion;
      return item.name;
    },
    async searchForPathway(search, loading) {
      const response = await this.$store.dispatch('app-roster/fetchPathways', { search });
      const pathways = response.data.results;

      this.pathwaySuggestions = pathways.map((p) => {
        let { name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { value: name, label: name };
      });
    },
  },
  setup(props, { emit }) {
    const PLACEMENT_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PLACEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(PLACEMENT_APP_STORE_MODULE_NAME, placementStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLACEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(PLACEMENT_APP_STORE_MODULE_NAME);
    });

    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);
    const pathwaySelected = ref(undefined);
    const pathwaySectionConfigs = {
      pathways: {
        limit: 10,
        label: 'Pathways',
        onSelected: (selected) => {
          pathwaySelected.value = selected.item;
          emit('update:pathwayFilter', selected.item);
        },
      },
    };

    const pathwayInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for pathways',
      class: 'form-control',
      name: 'ajax',
    };
    return {
      pathwayQuery,
      pathwaySuggestions,
      pathwaySectionConfigs,
      pathwayInputProps,
      pathwaySelected,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
