import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useTemplateList() {
  // Use toast
  const toast = useToast();

  const refTemplateListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // {key: 'id', label: '#', sortable: true},
    { key: 'name', sortable: true },
    { key: 'type', sortable: true },
    { key: 'label', sortable: true },
    { key: 'subject', sortable: true },
    { key: 'partial', sortable: true },
    { key: 'enabled', sortable: true },
    // { key: 'name', sortable: true },
    // { key: 'email', sortable: true },
    // { key: 'total', sortable: true, formatter: val => `$${val}` },
    { key: 'createdAt', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalTemplates = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const statusFilter = ref(null);
  const pathwayFilter = ref(null);
  const createAtStartFilter = ref(null);
  const createAtEndFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refTemplateListTable.value ? refTemplateListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTemplates.value,
    };
  });

  const refetchData = () => {
    refTemplateListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, createAtStartFilter, createAtEndFilter, pathwayFilter, statusFilter], () => {
    refetchData();
  });

  const fetchTemplates = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,
      populate: '',
    };

    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }
    if (createAtStartFilter.value) {
      pl.createAtStart = createAtStartFilter.value;
    }
    if (createAtEndFilter.value) {
      pl.createAtEnd = createAtEndFilter.value;
    }

    store
      .dispatch('app-placement/fetchTemplates', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        console.log(results);

        callback(results);
        totalTemplates.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching monday template list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  return {
    fetchTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTemplateListTable,

    statusFilter,
    pathwayFilter,
    createAtStartFilter,
    createAtEndFilter,
    refetchData,
  };
}
